import { axiosClient } from '@/services/axios-client';

const sendEmailVerificationCode = (email) => {

    delete axiosClient.defaults.headers['Authorization'];

    const cfToken = sessionStorage.getItem('cf-token');

    const postParams = [
        '/accounts/users/send-verification-code',
        {
            email,
            ...(cfToken && { token: cfToken}),
        },
    ];

    return axiosClient.post(...postParams);
};

const sendMobileDownloadLink = (email) => {
    const formData = new FormData();
    formData.append('email', email);

    return axiosClient.post('/desktop/api.windows/v2/webutils/sendEmailForDownloadMobile/', formData, { headers: { 'Content-Type': 'multipart/form-data' }});
};


const getUserInfo = (userId) => {
    return axiosClient.get(`/accounts/users/${userId}`);
};

const generateXSollaUrl = (userId, userInfo, items, payload, gifting = {}) => {
    const { email } = userInfo;
    const userInfoPurchase = {
        user_id: userId,
        user_email: email,
        items,
        payload,
        items_type: 'virtual_item',
        ...gifting,
    };

    return axiosClient.post('/desktop/api.windows/v2/webutils/getPaymentToken/', userInfoPurchase, { headers: { 'Content-Type': 'text/plain;charset=UTF-8' }});
};

const generatePaddleUrl = (userId, userInfo, items, payload) => {
    const { email } = userInfo;
    const userInfoPurchase = {
        user_id: userId,
        user_email: email,
        items,
        payload,
    };

    return axiosClient.post('/desktop/api.windows/v2/webutils/getPaddlePaymentToken/', userInfoPurchase, { headers: { 'Content-Type': 'text/plain;charset=UTF-8' }});
};

const generateXSollaUrlForFlagship = (userId, userInfo, items, payload) => {
    const { email } = userInfo;
    const itemType = payload?.planType === 'oneTimePayment' ? 'one_time' : 'renewable';
    const userInfoPurchase = {
        user_id: userId,
        user_email: email,
        subscription: {
            id: items,
            type: itemType,
        },
        items_type: 'virtual_item',
    };

    return axiosClient.post('/orders/subscriptions/purchase', userInfoPurchase);
};

const generatePaddleTokenForFlagship = (userId, userInfo, items, payload) => {
    const { email } = userInfo;
    const itemType = payload?.planType === 'oneTimePayment' ? 'one_time' : 'renewable';

    const userInfoPurchase = {
        user_id: userId,
        user_email: email,
        subscription: {
            id: items,
            type: itemType,
            provider: 'paddle',
        },
        items_type: 'virtual_item',
    };

    return axiosClient.post('/orders/subscriptions/purchase', userInfoPurchase);

};

const getGiftProducts = () => {
    return axiosClient.get('/desktop/api.windows/v2/webutils/getprices/9e67a32c-d044-4c14-a4b0-56f14df3ec9a');
};

const updateUserConsents = (userId, userConsents) => {
    return axiosClient.patch(`/accounts/users/${userId}/consents`, userConsents);
};

const cancelUserSubscription = (userId, subscriptionId, source) => {
    const subscriptionInfo = {
        subscriptionId,
        source,
    };

    return axiosClient.patch(`/accounts/users/${userId}/subscription`, subscriptionInfo);
};

const updateNickName = (userId, nickName) => {
    return axiosClient.patch(`/accounts/users/${userId}/nick-name`, {
        nickName,
    });
};

const updateConsent = (userId, consent) => axiosClient.patch(`/accounts/users/${userId}/consent`, consent);

const deleteAccount = (userId) => axiosClient.delete(`/accounts/users/${userId}`);

export const AccountClient = {
    sendEmailVerificationCode,
    sendMobileDownloadLink,
    getUserInfo,
    generateXSollaUrl,
    generatePaddleUrl,
    updateUserConsents,
    cancelUserSubscription,
    generateXSollaUrlForFlagship,
    generatePaddleTokenForFlagship,
    getGiftProducts,
    updateNickName,
    updateConsent,
    deleteAccount,
};