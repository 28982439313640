import { axiosClient } from '@/services/axios-client';

const checkPaymentStatus = (checkoutData) => {
    const legacyPaymentCheckUrl = '/desktop/api.windows/v2/windowsb2c/payment-check/';
    const checkoutParams = {
        ...(checkoutData.productId && {productId: checkoutData.productId}),
        ...(checkoutData.skus && {skus: checkoutData.skus.join(',')}),
        userId: checkoutData.userId,
        token: checkoutData.token,
    };

    const formData = new FormData();

    Object.keys(checkoutParams).forEach((element) => {
        formData.append(`${element}`, `${checkoutParams[element]}`);
    });

    return axiosClient.post(legacyPaymentCheckUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
};

const checkPaddlePaymentStatus = (checkoutData = {}) => {
    const legacyPaymentCheckUrl = '/desktop/api.windows/v2/windowsb2c/payment-check/';
    const formData = new FormData();

    Object.keys(checkoutData).forEach((element) => {
        formData.append(element, checkoutData[element]);
    });

    return axiosClient.post(legacyPaymentCheckUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
};

const CheckoutClient = {
    checkPaymentStatus,
    checkPaddlePaymentStatus,
};

export { CheckoutClient };