<template>
  <div class="payment-check">
    <img src="@/assets/images/voicemod-loader.gif">
    <div class="payment-check__text">
      {{ $t('checkout.checking-purchase') }}
    </div>
  </div>
</template>

<script>
import { CheckoutClient } from '@/services/models/checkout';
import { AccountClient } from '@/services/models/accounts';
import { ROUTER_PATHS } from '@/router/paths';

const EXCEDED_CHECKOUT_TIME = 60000;
const POLLING_PERIOD = 5000;

export default {
    data() {
        return {
            intervalId: null,
            timeoutId: null,
            checkoutData: null,
        };
    },
    mounted() {
        const userId = this.$route.query.userId;
        const productId = this.$route.query.productId;
        const client = this.$route.query.client;

        if (!userId || !productId) {
            this.goToError();
        }

        const checkoutData = {
            user_id: userId,
            product_id: productId,
            token: null,
            ...(client && { client }),
        };

        if (checkoutData.client) {
            this.checkPurchaseForFlagship(checkoutData);
            this.doPoolingForFlagship(checkoutData);
        }
        if (!checkoutData.client) {
            this.checkPurchase(checkoutData);
            this.doPooling(checkoutData);
        }

        this.finishOnTimeout();
    },
    methods: {
        goToError() {
            this.$router.push(ROUTER_PATHS.PAYMENT_ERROR);
        },
        async checkPurchase(checkoutData) {
            const { status } = await CheckoutClient.checkPaddlePaymentStatus(checkoutData);
            if (status === 200) {
                this.clearAllIntervals([this.intervalId, this.timeoutId]);
                this.$router.push(ROUTER_PATHS.PAYMENT_SUCCESS);
                return;
            }
        },
        async checkPurchaseForFlagship({ user_id }) {
            const { data: userInfo } = await AccountClient.getUserInfo(user_id);

            if (userInfo.subscription.subscriptionType === 'pro') {
                this.clearAllIntervals([this.intervalId, this.timeoutId]);
                this.$router.push({
                    path: ROUTER_PATHS.PAYMENT_SUCCESS,
                    query: {
                        client: 'flagship',
                    },
                });
                return;
            }
        },
        doPoolingForFlagship(checkoutData) {
            setTimeout(() => {
                this.intervalId = setInterval(async () => {
                    await this.checkPurchaseForFlagship(checkoutData);
                }, POLLING_PERIOD);
            }, POLLING_PERIOD);
        },
        clearAllIntervals(timings) {
            timings.forEach((timings) => {
                clearInterval(timings);
                clearTimeout(timings);
            });
        },
        doPooling(checkoutData) {
            setTimeout(() => {
                this.intervalId = setInterval(async () => {
                    await this.checkPurchase(checkoutData);
                }, POLLING_PERIOD);
            }, POLLING_PERIOD);
        },
        finishOnTimeout() {
            this.timeoutId = setTimeout(() => {
                this.clearAllIntervals([this.intervalId]);
                this.$router.push(ROUTER_PATHS.PAYMENT_ERROR);
            },EXCEDED_CHECKOUT_TIME);
        },
    },
};
</script>

<style src="./PaddlePaymentCheckView.scss" lang="scss" />
